var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",class:{'page-view': _vm.view == 'view' && _vm.mode === 'view'}},[(_vm.mode === 'view')?_c('div',{staticClass:"view"},[_c('div',{staticClass:"basic"},[(_vm.canEdit)?_c('div',{staticClass:"edit-btn",on:{"click":_vm.goEdit}},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("编辑")])],1):_vm._e(),_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"ruleForm",attrs:{"model":_vm.ruleForm}},[_c('div',{staticClass:"basic-form basic-form-view"},[_c('div',{staticClass:"view-title"},[_c('div',{staticClass:"view-flag"}),_c('div',{staticClass:"title"},[_vm._v("基础信息")])]),_c('div',{staticClass:"baseMain"},[_c('div',{staticClass:"rt"},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-checked"},[_vm._v(" 登录账号 ")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"baseCol baseCol-first"},[_vm._v(" "+_vm._s(_vm.ruleForm.loginName)+" ")])])],1),_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 登录密码 ")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"baseCol baseCol-first baseCol-top"},_vm._l((_vm.ruleForm
                                                        .password.length),function(ps){return _c('span',{key:ps},[_vm._v("●")])}),0)])],1),_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 用户角色 ")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"baseCol baseCol-first baseCol-top"},[_c('span',{attrs:{"title":_vm.teacherRoleList}},[_vm._v(" "+_vm._s(_vm.teacherRoleList)+" ")])])])],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-img baseCol-checked"},[_vm._v(" 头像 ")])]),_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"lt"},[_c('img',{staticClass:"upload-img",attrs:{"src":_vm.ruleForm.headImg ||
                                                        _vm.uploadAvatar}})])])],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 姓名 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[_vm._v(" "+_vm._s(_vm.ruleForm.teacherName)+" ")])]),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-second baseCol-checked"},[_vm._v(" 手机号 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[_vm._v(" "+_vm._s(_vm.ruleForm.phone)+" ")])])],1),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 工号 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[_vm._v(" "+_vm._s(_vm.ruleForm.onlyCode)+" ")])]),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-second baseCol-checked"},[_vm._v(" 性别 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[(_vm.ruleForm.sex == 1)?_c('div',[_vm._v(" 男 ")]):_c('div',[_vm._v("女")])])])],1),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 任职部门 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[(_vm.ruleForm.orgList.length)?_vm._l((_vm.ruleForm.orgList),function(item){return _c('span',{key:item.id,staticClass:"post-item"},[_vm._v(_vm._s(item.name))])}):[_c('div')]],2)]),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-second baseCol-checked"},[_vm._v(" 是否任教 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[(
                                                _vm.ruleForm.isSubjectTeacher ==
                                                1
                                            )?_c('div',[_vm._v(" 是 ")]):_c('div',[_vm._v("否")])])])],1),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 班主任 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[(
                                                _vm.ruleForm.isHeadmaster == 1
                                            )?_c('div',[_vm._v(" 是 ")]):_c('div',[_vm._v("否")])])]),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-second baseCol-checked manageClass"},[_vm._v(" 负责班级 "),_c('div',{staticClass:"table-header"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"班主任负责班级","placement":"top","offset":30}},[_c('i',{staticClass:"el-icon-question label-description"})])],1)])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[(
                                                _vm.ruleForm.manageClassList
                                                    .length
                                            )?[(
                                                    _vm.ruleForm.manageClassList
                                                        .length > 6
                                                )?_c('el-popover',{attrs:{"placement":"top-start","title":"班主任负责班级","width":"200","trigger":"hover"}},[_vm._l((_vm.ruleForm.manageClassList),function(item){return _c('span',{key:item.id,staticClass:"post-item"},[_vm._v(_vm._s(item.name))])}),_c('div',{staticClass:"refer",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" ... ")])],2):_vm._e(),_vm._l((_vm.ruleForm.manageClassList),function(cl,c){return _c('span',{key:c,staticClass:"post-item"},[_vm._v(" "+_vm._s(cl.name)+" ")])})]:[_c('div')]],2)])],1),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked classShow"},[_vm._v(" 班级权限 "),_c('div',{staticClass:"table-header2"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"班级数据权限，可查看哪些班级的数据","placement":"top","offset":30}},[_c('i',{staticClass:"el-icon-question label-description"})])],1)])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},[(_vm.ruleForm.viewList.length)?[(
                                                    _vm.ruleForm.viewList
                                                        .length > 13
                                                )?_c('el-popover',{attrs:{"placement":"top-start","title":"可查看班级数据","width":"200","trigger":"hover"}},[_vm._l((_vm.ruleForm.viewList),function(item){return _c('span',{key:item.id,staticClass:"post-item"},[_vm._v(_vm._s(item.name))])}),_c('div',{staticClass:"refer",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" ... ")])],2):_vm._e(),_vm._l((_vm.ruleForm.viewList),function(item){return _c('span',{key:item.id,staticClass:"post-item"},[_vm._v(_vm._s(item.name))])})]:[_c('div')]],2)]),_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-second baseCol-checked"},[_vm._v(" 任教科目 ")])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"baseCol baseCol-second"},_vm._l((_vm.subJectList),function(sub,subi){return _c('span',{key:subi,staticClass:"post-item"},[_vm._v(" "+_vm._s(sub)+" ")])}),0)])],1),_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('div',{staticClass:"baseCol baseCol-top baseCol-checked"},[_vm._v(" 职务 ")])]),_c('el-col',{attrs:{"span":20}},[_c('div',{staticClass:"baseCol baseCol-second"},[(_vm.showPostList.length)?_vm._l((_vm.showPostList),function(post,posti){return _c('span',{key:posti,staticClass:"post-item"},[_vm._v(_vm._s(post))])}):[_c('div')]],2)])],1)],1)])])]),(_vm.oriBasicForm.id)?_c('div',{staticClass:"detail-form"},[_c('StuInfoFill',{ref:"stuInfoFill",attrs:{"type":"teacher","mode":_vm.view,"school-id":_vm.ruleForm.schoolId,"stu-basic-info":_vm.oriBasicForm}})],1):_vm._e()],1)]):_vm._e(),(_vm.mode === 'edit')?_c('div',{staticClass:"edit-form"},[_c('SummaryTeacherInfo',{attrs:{"query":_vm.editInfoParams},on:{"change":_vm.handlerGlobalPageBack,"setPageInitTabs":_vm.setPageInitTabs}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }