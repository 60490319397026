<template>
    <el-scrollbar class="home-container">
        <div class="content" v-if="!isShowBody">
            <div class="content_top">
                <home-statistics @isShowMyCurriculum="isShowMyCurriculum"></home-statistics>
            </div>
             <div class="home-content">
                <div class="home-content__left">
                    <home-commonly-used></home-commonly-used>
                    <div class="home-content__left__info">
                        <home-school-calendar></home-school-calendar>
                        <home-notice
                            v-has-permi="['announcement:list']"
                        />
                    </div>
                </div>
                <div class="home-content__right">
                    <home-pending ></home-pending>
                </div>
            </div>
        </div>
        <div class="home-content" v-if="isShowBody">
            <course-schedule
                ref="courseScheduleWrapper"
                @closeMyCurriculum="closeMyCurriculum"
            ></course-schedule>
        </div>
        <div class="copyright-wrap">
            河北鑫考科技股份有限公司 版权所有 © 2008-2023 Xinkaoyun.com, All Rights Reserved | 邮编053000 | 电话400-189-0086 | 邮箱：834701@xinkaojiaoyu.con
        </div>
        <!-- 更新公告 -->
        <update-notice :fromPath="fromPath"></update-notice>
    </el-scrollbar>
</template>

<script>
import {mapState} from "vuex";
import HomeNotice from "@/components/scrollWrapper/firstPage/HomeNoticeCustom.vue";
import HomeCommonlyUsed from "@/components/scrollWrapper/firstPage/HomeCommonlyUsed.vue";
import HomeApplications from "@/components/scrollWrapper/firstPage/HomeApplications.vue";
import HomeIntroduce from "@/components/scrollWrapper/firstPage/HomeIntroduce.vue";
import PersonalInfor from "@/components/scrollWrapper/firstPage/PersonalInfor.vue";
import HomePending from "@/components/scrollWrapper/firstPage/HomePendingCustom.vue";
import CourseSchedule from "./firstPage/MyCurriculum/CourseSchedule.vue";
import UpdateNotice from "@/components/scrollWrapper/firstPage/UpdateNotice.vue";
import HomeStatistics from "@/components/scrollWrapper/firstPage/HomeStatistics.vue";
import HomeSchoolCalendar from "@/components/scrollWrapper/firstPage/HomeSchoolCalendar.vue";

export default {
    name: 'WorktopCustomScrollWrapper',
    props: {
        fromPath: {
            type: String,
        }
    },
    components: {
        HomeNotice,
        HomeCommonlyUsed,
        HomeApplications,
        HomeIntroduce,
        HomePending,
        PersonalInfor,
        CourseSchedule,
        UpdateNotice,
        HomeStatistics,
        HomeSchoolCalendar
    },
    computed: {
        ...mapState({
            originRouters: (state) => state.originRouters,
        }),
    },
    data() {
        return {
            introduceRouteName: "SummaryIntroduce",
            isShowBody: false,
        };
    },
    created() {
        console.log('this.fromPath',this.fromPath);
    },
    methods: {
        hasPermission(name) {
            return this.originRouters.findIndex((e) => e.name === name) > -1;
        },
        isShowMyCurriculum(e) {
            console.log('eeeee',e);
            this.isShowBody = e;
        },
        closeMyCurriculum() {
            // 关闭我的课表
            this.isShowBody = false;
        },
    },
};
</script>

<style scoped lang="scss">
.home-container {
    font-family: Microsoft YaHei;
    height: calc(100% - 76px);
    .content {
        width: 1411px;
        margin: 0 auto;
        .content_top {
        }
    }
    .home-content {
        display: flex;
        justify-content: space-between;
        width: 1411px;
        margin: 0 auto;

        &__left {
            width: 937px;
            &__info {
                display: flex;
                justify-content: space-between;
            }
        }

        &__right {
            width: 460px;
        }
    }

    .copyright-wrap {
        width: 100%;
        text-align: center;
        line-height: 13px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin: 8px auto 26px;
    }
}

::v-deep .home-commonLy-used {
  margin-bottom: 13px;
}

::v-deep .el-scrollbar__wrap {
    height: calc(100% + 17px);
}
</style>
