<template>
    <div
        class="page"
        :class="{'page-view': view == 'view' && mode === 'view'}"
    >
        <!-- 详情 -->
        <div
            class="view"
            v-if="mode === 'view'"
        >
            <!-- 基础信息 -->
            <div class="basic">
                <!-- 编辑按钮 -->
                <div
                    class="edit-btn"
                    v-if="canEdit"
                    @click="goEdit"
                >
                    <el-button type="primary">编辑</el-button>
                </div>
                <el-form
                    v-loading="loading"
                    :model="ruleForm"
                    ref="ruleForm"
                >
                    <div class="basic-form basic-form-view">
                        <div class="view-title">
                            <div class="view-flag"></div>
                            <div class="title">基础信息</div>
                        </div>
                        <div class="baseMain">
                            <div class="rt">
                                <el-row>
                                    <el-col :span="12">
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-checked"
                                                >
                                                    登录账号
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div
                                                    class="baseCol baseCol-first"
                                                >
                                                    {{ ruleForm.loginName }}
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-top baseCol-checked"
                                                >
                                                    登录密码
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div
                                                    class="baseCol baseCol-first baseCol-top"
                                                >
                                                    <span
                                                        v-for="ps in ruleForm
                                                            .password.length"
                                                        :key="ps"
                                                        >●</span
                                                    >
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-top baseCol-checked"
                                                >
                                                    用户角色
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div
                                                    class="baseCol baseCol-first baseCol-top"
                                                >
                                                    <span :title="teacherRoleList">
                                                        {{ teacherRoleList }}
                                                    </span>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-img baseCol-checked"
                                                >
                                                    头像
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div class="lt">
                                                    <img
                                                        :src="
                                                            ruleForm.headImg ||
                                                            uploadAvatar
                                                        "
                                                        class="upload-img"
                                                    />
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <!-- <div class="baseCol">登陆密码</div>
                        <div class="baseCol">所属角色</div> -->
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            姓名
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{ ruleForm.teacherName }}
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            手机号
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{ ruleForm.phone }}
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            工号
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{ ruleForm.onlyCode }}
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            性别
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <div v-if="ruleForm.sex == 1">
                                                男
                                            </div>
                                            <div v-else>女</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            任职部门
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <!-- ruleForm.orgList -->
                                            <template
                                                v-if="ruleForm.orgList.length"
                                            >
                                                <span
                                                    v-for="item in ruleForm.orgList"
                                                    :key="item.id"
                                                    class="post-item"
                                                    >{{ item.name }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            是否任教
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <div
                                                v-if="
                                                    ruleForm.isSubjectTeacher ==
                                                    1
                                                "
                                            >
                                                是
                                            </div>
                                            <div v-else>否</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            班主任
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <!-- {{ruleForm.isHeadmaster}} -->
                                            <div
                                                v-if="
                                                    ruleForm.isHeadmaster == 1
                                                "
                                            >
                                                是
                                            </div>
                                            <div v-else>否</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked manageClass"
                                        >
                                            负责班级

                                            <div class="table-header">
                                                <el-tooltip
                                                    effect="dark"
                                                    content="班主任负责班级"
                                                    placement="top"
                                                    :offset="30"
                                                >
                                                    <i
                                                        class="el-icon-question label-description"
                                                    ></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <!-- ruleForm.manageClassList -->
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="
                                                    ruleForm.manageClassList
                                                        .length
                                                "
                                            >
                                                <el-popover
                                                    placement="top-start"
                                                    title="班主任负责班级"
                                                    width="200"
                                                    trigger="hover"
                                                    v-if="
                                                        ruleForm.manageClassList
                                                            .length > 6
                                                    "
                                                >
                                                    <span
                                                        v-for="item in ruleForm.manageClassList"
                                                        :key="item.id"
                                                        class="post-item"
                                                        >{{ item.name }}</span
                                                    >

                                                    <div
                                                        slot="reference"
                                                        class="refer"
                                                    >
                                                        ...
                                                    </div>
                                                    <!-- <button slot="reference" >触发</button> -->
                                                </el-popover>
                                                <span
                                                    v-for="(
                                                        cl, c
                                                    ) in ruleForm.manageClassList"
                                                    :key="c"
                                                    class="post-item"
                                                >
                                                    {{ cl.name }}
                                                </span>
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                                <!--  -->
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked classShow"
                                        >
                                            班级权限

                                            <div class="table-header2">
                                                <el-tooltip
                                                    effect="dark"
                                                    content="班级数据权限，可查看哪些班级的数据"
                                                    placement="top"
                                                    :offset="30"
                                                >
                                                    <i
                                                        class="el-icon-question label-description"
                                                    ></i>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="ruleForm.viewList.length"
                                            >
                                                <el-popover
                                                    placement="top-start"
                                                    title="可查看班级数据"
                                                    width="200"
                                                    trigger="hover"
                                                    v-if="
                                                        ruleForm.viewList
                                                            .length > 13
                                                    "
                                                >
                                                    <span
                                                        v-for="item in ruleForm.viewList"
                                                        :key="item.id"
                                                        class="post-item"
                                                        >{{ item.name }}</span
                                                    >

                                                    <div
                                                        slot="reference"
                                                        class="refer"
                                                    >
                                                        ...
                                                    </div>
                                                </el-popover>
                                                <span
                                                    v-for="item in ruleForm.viewList"
                                                    :key="item.id"
                                                    class="post-item"
                                                    >{{ item.name }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            任教科目
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <span
                                                v-for="(
                                                    sub, subi
                                                ) in subJectList"
                                                :key="subi"
                                                class="post-item"
                                            >
                                                {{ sub }}
                                            </span>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            职务
                                        </div>
                                    </el-col>
                                    <el-col :span="20">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="showPostList.length"
                                            >
                                                <span
                                                    v-for="(
                                                        post, posti
                                                    ) in showPostList"
                                                    :key="posti"
                                                    class="post-item"
                                                    >{{ post }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-form>

                <!-- 档案 -->
                <div
                    v-if="oriBasicForm.id"
                    class="detail-form"
                >
                    <StuInfoFill
                        ref="stuInfoFill"
                        type="teacher"
                        :mode="view"
                        :school-id="ruleForm.schoolId"
                        :stu-basic-info="oriBasicForm"
                    />
                </div>
            </div>
        </div>
        <!-- 编辑 -->
        <div class="edit-form" v-if="mode === 'edit'">
          <SummaryTeacherInfo
            :query="editInfoParams"
            @change="handlerGlobalPageBack"
            @setPageInitTabs="setPageInitTabs"
          />
        </div>
    </div>
</template>

<script>
import {StuInfoFill} from "common-local";
import SummaryTeacherInfo from "@/components/scrollWrapper/SummaryTeacherInfo.vue";
import {listToTree} from "@/libs/utils.js";
import {getToken} from "@/libs/auth";

export default {
    name: "",
    components: {
        StuInfoFill,
        SummaryTeacherInfo
    },
    data() {
        return {
            mode: "view", //view查看 edit编辑
            canEdit: false, //是否有编辑权限，有则显示编辑按钮
            loading: false,
            view: "view",
            editInfoParams: {},
            ruleForm: {
                teacherName: "",
                remark: "",
                id: "",
                schoolId: "",
                phone: "",
                onlyCode: "",
                status: 1,
                sex: "1",
                classType: 1,
                post: [],
                postName: "",
                orgList: [],
                headImg: "",
                isHeadmaster: "2",
                isSubjectTeacher: "2",
                subjectClassList: [],
                manageClassList: [],
                viewList: [],
                subjectName: "",
                subject: "",
                loginName: "",
                password: "",
                roleId: "",
            },

            options: [],
            options2: [],
            subjects: [],
            postList: [],
            showPostList: [],
            oriBasicForm: {},
            detailFormShow: false,
            roleList: [],
            disabledPassword: false,
            teacherRoleList: '',
            subJectList: [],
            teacherId: "",
        };
    },
    filters: {},
    computed: {
        triangleUp() {
            return require("@/assets/images/stuInfoTemplate/triangle-up.png");
        },
        triangleDown() {
            return require("@/assets/images/stuInfoTemplate/triangle-down.png");
        },
        uploadAvatar() {
            return require("@/assets/images/imgErrorAdr.png");
        },
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    watch: {},
    created() {
        this.init();
        console.log(this.ruleForm, "ruleForm");
        // 获取编辑权限
        this.getEditPermission();
    },
    mounted() {
        this.$nextTick(() => {});
    },
    methods: {
        /**
         * @Descripttion: 初始化
         * @DoWhat:初始化获取数据
         * @UseScenarios: 获取教师档案信息
         * @Attention:
         * @Author: lyx
         * @Date: 2022-11-22 17:19:23
         */
        async init() {
            this.loading = true;
            this.ruleForm.schoolId = this.$store.state.schoolId;
            this.teacherId = this.$store.state.teacherId;
            await this.getDictList();
            await this.getOrgList();
            await this.getRoleList();
            await this.getTeacherInfo();
            this.loading = false;
            console.log(this.postList, this.ruleForm.post, "this.postList");
            this.showPostList = [];
            this.postList.map((item, index) => {
                this.ruleForm.post.map((i) => {
                    if (i == item.value) {
                        this.showPostList.push(item.label);
                    }
                });
            });
            let teacherRoleListArr = [];
            this.roleList.map((item) => {
                this.ruleForm.roleIdList.map(i=>{
                    console.log(item.id, i, "item.id");
                    if(i == item.id){
                        teacherRoleListArr.push(item.roleName);
                    }
                    this.teacherRoleList = teacherRoleListArr.join("、");
                })
            });
            this.subjects.map((item) => {
                if (item.value == this.ruleForm.subject) {
                    this.subJectList.push(item.label);
                }
            });
        },
        /**
         * @Descripttion: 获取教师基本信息
         * @DoWhat: 获取教师基本信息
         * @UseScenarios: 获取教师基本信息
         * @Attention:
         * @Author: lyx
         * @Date: 2022-11-22 17:19:56
         */
        async getTeacherInfo() {
            let r = await this._fet(
                `/school/schoolTeacher/getTeacherInfo/${this.teacherId}`,
            );
            if (r.data.code === "200") {
                let detail = r.data.data;
                console.log("[getTeacherInfo]", detail);
                Object.assign(
                    this.ruleForm,
                    detail,
                    {orgList: detail.deptList},
                    {manageClassList: detail.masterClassList},
                );
                console.log("[getTeacherInfo]this.ruleForm", this.ruleForm);
                if (this.ruleForm.post && this.ruleForm.post.length > 0) {
                    this.ruleForm.post = this.ruleForm.post.split(",");
                } else {
                    this.ruleForm.post = [];
                }
                this.oriBasicForm = JSON.parse(JSON.stringify(this.ruleForm));
            } else {
                this.$message.error(r.data.msg);
            }
        },
        async getOrgList() {
            let res = await this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.$store.state.schoolId,
            });
            if (res.data.code === "200") {
                const list = res.data.data.list;
                const list1 = list.filter((it) => it.organType == 1);
                const list2 = list.filter((it) => it.organType != 1);
                this.options = listToTree(list1, {
                    parentKey: "parentOrg",
                });
                this.options2 = listToTree(list2, {
                    parentKey: "parentOrg",
                });
            } else {
                this.$message.error(res.data.msg);
            }
        },
        /**
         * @Descripttion: 获取教师职务
         * @DoWhat: 获取教师职务
         * @UseScenarios: 获取教师职务
         * @Attention:
         * @Author: lyx
         * @Date: 2022-11-22 17:20:53
         */
        async getDictList() {
            let res = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    dictKey: "teachingPost",
                    schoolId: this.$store.state.schoolId,
                },
            );
            if (res.data.code === "200") {
                this.postList = res.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                this.postList.unshift({label: "全部", value: ""});
            } else {
                this.$message.error(res.data.msg);
            }

            let res2 = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    schoolId: this.$store.state.schoolId,
                    dictKey: "teachingSubjects",
                },
            );
            if (res2.data.code === "200") {
                this.subjects = res2.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            } else {
                this.$message.error(res2.data.msg);
            }
        },
        /**
         * @Description 获取角色下拉数据
         * @DoWhat 获取角色下拉数据
         * @UseScenarios 选择所属角色
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/8 17:04
         **/
        async getRoleList() {
            await this._fet("/school/schoolRole/getRoleList", {
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.roleList = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 获取编辑权限
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-09-01 10:26:28
         */
        getEditPermission() {
            this._fet("/school/schoolStudent/getStuArchives", {
                archivesPersonType: "2",
                schoolId: this.$store.state.schoolId,
                id: this.teacherId,
            })
                .then((res) => {
                  console.log("[获取个人档案的编辑权限]", res);
                  if (res.data.code == "200") {
                    if (res.data.data.isInSetting) {
                      this.canEdit = res.data.data.list && res.data.data.list.length > 0; 
                    } else {
                      this.canEdit = false;
                    }
                  }
                })
                .catch((err) => {
                  console.log("[获取个人档案的编辑权限err]", err);
                });
        },
        /**
         * @Description: 点击编辑--展示编辑页面
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-09-01 11:10:07
         */        
        goEdit(){
          this.mode = "edit";
          this.editInfoParams = {
            pageType: 'edit',
            pageSource: 'userProfile',
            schoolId: this.$store.state.schoolId,
            row: this.ruleForm,
            pageBackTitle: '编辑',
            teacherId: this.teacherId,
          }
        },
         handlerGlobalPageBack () {
            this.mode = 'view'
         },
         setPageInitTabs(){
            this.handlerGlobalPageBack()
         },
        filterFn(data) {
            return data.filter((i) => i.organType === "4");
        },
        postName(e) {
            this.$nextTick(() => {
                this.ruleForm.postName = this.$refs.postSelect.selected
                    .map((i) => i.label)
                    .join(",");
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addNewSector(this.ruleForm);
                } else {
                    return false;
                }
            });
        },
        addNewSector(ruleForm) {
            let form = JSON.parse(JSON.stringify(ruleForm));
            if (!form.id) {
                delete form.id;
            }
            form.post = form.post.toString();
            // 获取科目名称
            let find = this.subjects.find((i) => i.value === form.subject);
            if (find) {
                form.subjectName = find.label;
            }
            if (form.isSubjectTeacher === "2") {
                form.subjectClassList = [];
                form.subjectName = "";
                form.subject = "";
            }
            if (form.isHeadmaster === "2") {
                form.manageClassList = [];
            }
            if (this.disabledPassword) {
                form.password = "";
            }
            this._fet("/school/schoolTeacher/save", form).then((res) => {
                console.log("[保存]", res);
                if (res.data.code == "200") {
                    this.oriBasicForm = res.data.data;
                    this.$message.success("成功");
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 展示或隐藏档案表单
        showStowDetailForm() {
            this.detailFormShow = !this.detailFormShow;
        },
        /**
         * @Description 上传头像成功回调
         * @DoWhat 上传头像成功回调
         * @UseScenarios 上传头像成功回调
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:05
         **/
        handleAvatarSuccess(res, file) {
            this.ruleForm.headImg = res.data.url;
        },
        /**
         * @Description 上传头像校验
         * @DoWhat 上传头像校验
         * @UseScenarios 上传头像校验
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:04
         **/
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
            }
            if (!isJPG && !isPNG) {
                this.$message.error("上传图片必须是JPG或PNG格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过2MB!");
            }
            return (isJPG || isPNG) && isLt2M && is50;
        },
        /**
         * @Description 修改手机号
         * @DoWhat 登录名默认为手机号
         * @UseScenarios 修改手机号
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:02
         **/
        changePhone(value) {
            this.ruleForm.loginName = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    padding: 16px;
    height: calc(100vh - 140px);
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
}

// 公共-有分割线的标题
::v-deep .line-title {
    text-align: center;
    display: flex;
    flex-direction: row;
}
::v-deep .line-title:before {
    content: "";
    flex: 1 1;
    width: 400px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}
::v-deep .line-title:after {
    content: "";
    flex: 1 1;
    width: 400px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}
// 公共-按钮
.btn {
    width: 88px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}
.btn-reset {
    background: #ebf1f7;
    color: #737373;
}
.btn-confirm {
    background: #3c7fff;
    color: #ffffff;
}

// 编辑按钮
.edit-btn {
    text-align: right;
}

// 基础信息
.basic {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
}
// 基础信息-头部
.basic-header,
.basic-header-view {
    display: flex;
    flex-direction: row;
    align-items: center;

    .back {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        cursor: pointer;
    }

    .basic-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
.basic-header-view {
    position: absolute;
    top: 13px;
    left: 20px;
}
// 基础信息-表单
.basic-form,
.basic-form-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 56px 10px 40px 40px;
    .baseTitle {
        width: 100%;
        text-align: left;
        // border-bottom: 1px solid black;
    }
    .baseMain {
        display: flex;
    }
    .lt {
        /deep/.el-upload {
            text-align: left;
        }

        .upload-img {
            width: 104px;
            height: 104px;
            border-radius: 6px;
        }

        .upload-text {
            font-size: 12px;
            color: #bfbfbf;
            line-height: 18px;
            margin-top: 16px;
            white-space: nowrap;
        }
    }
    .rt {
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        .el-form-item {
            padding-left: 40px;
            box-sizing: border-box;
            width: 50%;

            /deep/ .el-form-item__label {
                padding-right: 0;
            }

            /deep/ .el-select {
                width: 100%;
            }
        }

        .div-divider {
            height: 1px;
            width: 100%;
            margin: 10px 0 24px 40px;
            background: #edf0f2;
        }
    }
    .tips {
        font-size: 12px;
        display: block;
        margin-top: -5px;
        height: 25px;
        color: #909399;
        white-space: nowrap;
    }
}
.basic-form-view {
    display: block;
    padding: 0;
    // border: 1px solid black;
    .rt {
        width: 100%;
        display: block;
    }
    .lt {
        height: 124px;
        border: 1px solid #919599;
        border-left: 1px solid rgba(255, 255, 255, 0);
        .upload-img {
            display: block;
            height: 104px;
            width: 104px;
            border-radius: 8px;
            margin: 10px auto 0;
        }
    }
}
.tac {
    text-align: center;
}

// 展示或收起按钮
.show-stow-btn {
    margin-top: 10px;
    height: 48px;
    background: #f7fafc;
    border-radius: 6px 6px 0px 0px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3c7fff;
    cursor: pointer;

    img {
        width: 12px;
        height: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
    }
}

// 组件组
::v-deep .comps-group-div {
    margin-top: 22px;
    .comps-group-item {
        padding: 0;
    }
}
// 更多信息表
.detail-form {
    background-color: #ffffff;
    border-radius: 0px 0px 6px 6px;
    // flex: 1;
}
.baseCol {
    border: 1px solid #919599;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #363b40;
    min-height: 42px;
    padding: 0 12px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.refer {
    position: absolute;
    top: 0;
    left: 302px;
    width: 20px;
    font-size: 14px;
    color: #363b40;
    // border: 1px solid black;
}
.baseCol-first {
    border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-secondTitle {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-second {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-right {
    border-right: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-top {
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-img {
    border-left: 1px solid rgba(255, 255, 255, 0);
    height: 126px;
    line-height: 126px;
    text-align: center;
}
.baseCol-checked {
    background-color: #edf4fa;
}
.view-title {
    width: 100%;
    height: 14px;
    line-height: 14px;
    display: flex;
    margin: 20px 0 16px;

    .view-flag {
        width: 3px;
        height: 14px;
        background-color: #3c7fff;
        margin-right: 8px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
    }
}
.post-item {
    margin: 0 5px;
}
.page-view {
    .basic {
        position: relative;
        margin: 0 auto;
        width: 1000px !important;
    }
    ::v-deep .stu-info-fill-main {
        padding: 0 0 24px 0 !important;
    }
    ::v-deep .el-form-item {
        margin-bottom: 0;
        border: 1px solid black;
        .explainText {
            display: none;
        }
        .el-checkbox-group {
            // box-sizing: border-box;
            overflow-y: scroll;
        }
        .el-radio-group {
            width: 100%;
            // padding-top: 10px;
            line-height: 47px !important;
            height: 36px;
            overflow-y: scroll;
        }

        .el-form-item__label {
            text-align-last: justify; // 最后一行两端对齐
            text-align: justify; // 两端对齐
            padding: 0 12px;
            width: 166px !important;
            // box-sizing: border-box;
            // background-color:#edf4fa;
        }

        .el-form-item__content {
            border-left: 1px solid black;
            // height: 36px;
            min-height: 36px;
            position: relative;
            margin-left: 166px !important;
            .explain {
                text-align: center !important;
                font-size: 14px !important;
                color: #363b40 !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .form-value-show-only,
            .el-radio,
            .el-checkbox-group,
            .explain {
                padding-left: 15px;
            }
            .el-checkbox-group {
                height: 36px;
            }
            .el-radio-group {
                height: 36px;
            }
            .upload-demo {
                padding-left: 5px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .avatar-uploader {
                padding: 5px 10px;
            }

            .input-div {
                position: absolute;
                height: 100%;
                .input {
                    .el-input__inner {
                        font-size: 14px;
                        color: #363b40;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
    /deep/ .el-table--border,
    .el-table--group {
        border: 1px solid #919599 !important;
    }
    /deep/ .el-table th.el-table__cell {
        color: #363b40 !important;
        font-size: 14px;
        font-weight: 400;
        background-color: #edf4fa !important;
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;
        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
        }
    }
    /deep/ .el-table__body td.el-table__cell {
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important ;
        font-size: 14px;
        color: #363b40;
        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
            // border-bottom: 1px solid #919599 !important;
        }
    }
    /deep/ .el-form-item {
        border: 1px solid #919599 !important;
        height: inherit !important;
        .el-input.is-disabled .el-input__inner {
            background-color: white !important;
            text-align: center;
        }

        .el-textarea.is-disabled .el-textarea__inner {
            background-color: white !important;
            font-size: 14px;
            color: 363b40;
        }
        .el-form-item__label {
            text-align: center;
            text-align-last: unset;
            font-size: 14px;
            color: #363b40;
            background-color: #edf4fa;
        }
        .el-form-item__content {
            border-left: 1px solid #919599 !important;
        }
        .avatar-uploader-icon {
            width: 148px !important;
            height: 148px !important;
            line-height: 148px !important;
        }
        .avatar-uploader {
            margin-top: 21px !important;
            // overflow: unset !important;
        }
        .el-upload {
            cursor: not-allowed !important;
        }
        // .el-input__inner{
        //   background-color: white !important;
        // }
    }
}
</style>
<style lang="scss" scoped>
.page-view .el-input__inner::placeholder {
    color: white !important;
}
.page-view .el-textarea__inner::placeholder {
    color: white !important;
}
.manageClass {
    position: relative;
    .table-header {
        position: absolute;
        top: -20%;
        right: 20%;

        display: inline-block;
        .label-description {
            cursor: pointer;
            margin-left: 6px;
        }
        .label-description:hover {
            color: #177ee6;
        }
    }
}
.classShow {
    position: relative;
    .table-header2 {
        position: absolute;
        top: -20%;
        right: 20%;

        display: inline-block;
        .label-description {
            cursor: pointer;
            margin-left: 6px;
        }
        .label-description:hover {
            color: #177ee6;
        }
    }
}
</style>
